<template>
    <form @submit.prevent="getSignatureCode" id="signatureCodeForm" ref="form">
      <div class="flex flex-col">
        <template v-for="field in userCertificateFields.filter(x => formFieldIsAvailable(x.checkVisibleField))">
          <ValidationProvider :name="field.text" :rules="field.rules" v-slot="{ errors, valid, invalid }" :key="field.name">
            <EuiTextField adaptSize
                          :warning="invalid && errors.length === 0"
                          :invalid="errors.length > 0"
                          :valid="valid">
              <EuiLabel>{{ field.text }}<span v-if="field.required">*</span></EuiLabel>
              <EuiInput :name="field.name"
                        v-model="signDialogData.certificate[field.name]"
                        :placeholder="field.placeholder"
                        :readonly="formFieldIsReadonly(field.checkEditableField)"
                        :warning="invalid && errors.length === 0"
                        :invalid="errors.length > 0"
                        :valid="valid"/>
              <template #errorMessage>
                <template v-for="(error) in errors">
                  {{ error }}
                </template>
              </template>
            </EuiTextField>
          </ValidationProvider>
        </template>
      </div>
      <EuiHeading :level="4" bold class="mb-4">{{ $t('signworkflows.sign.retrieveMode') }}</EuiHeading>
      <EuiRadio v-model="signatureRetrieveMode" name="signatureRetrieveMode" value="sms">{{ $t('signworkflows.sign.retrieveMode.sms') }}</EuiRadio>
      <EuiRadio v-model="signatureRetrieveMode" name="signatureRetrieveMode" value="email">{{ $t('signworkflows.sign.retrieveMode.email') }}</EuiRadio>
      <EuiRadio v-model="signatureRetrieveMode" name="signatureRetrieveMode" value="phone">{{ $t('signworkflows.sign.retrieveMode.phone') }}</EuiRadio>
      <template v-if="!isMobile && signDialogData.isSignatureCodeMandatory && isUsbSignAvailable">
        <EuiDivider secondary/>
        <EuiHeading :level="4" bold class="mb-4">{{ $t('signworkflows.sign.usbKey') }}</EuiHeading>
        <!-- sign with usb key -->
        <button class="es-sign-dialog__link"
                @click.prevent="signDialogData.goToStep(3.2)"
                v-html="$t('signworkflows.sign.retrieveMode.usb')">
        </button>
        <EuiDivider secondary/>
      </template>
    </form>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  name: 'SendCode',
  inject: ['signDialogData'],
  computed: {
    ...mapGetters({
      options: 'ModuleEdocSign/auth/options',
      user: 'ModuleEdocSign/auth/user',
    }),
    ...mapState({
      isMobile: (state) => state.application.isMobile,
    }),
  },
  props: {
    isUsbSignAvailable: {
      type: Boolean,
    },
  },
  watch: {
    signatureRetrieveMode(v) {
      this.signDialogData.certificate.signatureRetrieveMode = v;
    },
  },
  methods: {
    ...mapActions({
      updateUser: 'ModuleEdocSign/signatory/updateSignatory',
      sendCodeBySMS: 'ModuleEdocSign/certificate/sendCodeBySMS',
      sendCodeByEmail: 'ModuleEdocSign/certificate/sendCodeByEmail',
      sendCodeByPhone: 'ModuleEdocSign/certificate/sendCodeByPhone',
      getUserInfos: 'ModuleEdocSign/auth/getUserInfos',
      updateUserInfos: 'ModuleEdocSign/auth/getUserInfos',
    }),
    getSignatureCode() {
      this.updateUser(this.signDialogData.certificate)
        .then(() => {
          this.$emit('certificateGenerated', this.signDialogData.certificate);
          this.updateUserInfos(this.signDialogData.certificate);
          switch (this.signDialogData.certificate.signatureRetrieveMode) {
            case 'sms':
              this.sendCodeBySMS().then(() => this.$emit('change', 'success'));
              break;
            case 'email':
              this.sendCodeByEmail().then(() => this.$emit('change', 'success'));
              break;
            case 'phone':
              this.sendCodeByPhone().then(() => this.$emit('change', 'success'));
              break;
          }
          this.signDialogData.goToStep(2);
        });
    },
    formFieldIsAvailable(value) {
      if (value === 'signerCertGenerationDefineAddress' && this.options[value] == null) {
        return true;
      }
      if (this.options && this.options[value]) {
        return parseInt(this.options[value].value);
      } return value === undefined || value === null;
    },
    formFieldIsReadonly(value) {
      if (this.options && this.options[value]) {
        return !parseInt(this.options[value].value);
      } if (value === undefined || value === null) {
        return false;
      }
      return false;
    },
  },
  data() {
    return {
      signatureRetrieveMode: 'sms',
      userCertificateFields: [
        {
          name: 'firstname',
          text: this.$i18n.t('signatory.table.firstname'),
          placeholder: this.$i18n.t('signatory.table.firstname'),
          checkEditableField: 'signerCertGenerationFirstnameEditionEnabled',
          checkVisibleField: null,
          required: true,
          rules: 'required',
        },
        {
          name: 'lastname',
          text: this.$i18n.t('signatory.table.name'),
          placeholder: this.$i18n.t('signatory.table.name'),
          checkEditableField: 'signerCertGenerationLastnameEditionEnabled',
          checkVisibleField: null,
          required: true,
          rules: 'required',
        },
        {
          name: 'email',
          text: this.$i18n.t('signatory.table.email'),
          placeholder: this.$i18n.t('signatory.form.email.placeholder'),
          checkEditableField: 'signerCertGenerationEmailEditionEnabled',
          checkVisibleField: null,
          required: true,
          rules: 'required|email',
        },
        {
          name: 'mobilePhone',
          text: this.$i18n.t('signatory.form.phone'),
          placeholder: this.$i18n.t('signatory.form.phone.placeholder'),
          checkEditableField: 'signerCertGenerationMobilePhoneEditionEnabled',
          checkVisibleField: null,
          required: true,
          rules: 'required|phone',
        },
        {
          name: 'address',
          text: this.$i18n.t('signatory.form.address'),
          placeholder: this.$i18n.t('signatory.form.address'),
          checkEditableField: null,
          checkVisibleField: 'signerCertGenerationDefineAddress',
          required: true,
          rules: 'required',
        },
        {
          name: 'postalCode',
          text: this.$i18n.t('signatory.form.zip'),
          placeholder: this.$i18n.t('signatory.form.zip.placeholder'),
          checkEditableField: null,
          checkVisibleField: 'signerCertGenerationDefineAddress',
          required: true,
          rules: 'required|postal_code',
        },
        {
          name: 'town',
          text: this.$i18n.t('signatory.form.town'),
          placeholder: this.$i18n.t('signatory.form.town'),
          checkEditableField: null,
          checkVisibleField: 'signerCertGenerationDefineAddress',
          required: true,
          rules: 'required',
        },
        {
          name: 'service',
          text: this.$i18n.t('signatory.form.jobSector'),
          placeholder: this.$i18n.t('signatory.form.jobSector'),
          checkEditableField: 'signerCertGenerationServiceEditionEnabled',
          checkVisibleField: 'signerCreationFormDefineService',
          required: false,
          rules: 'required',
        },
        {
          name: 'function',
          text: this.$i18n.t('signatory.form.jobRole'),
          placeholder: this.$i18n.t('signatory.form.jobRole'),
          checkEditableField: 'signerCertGenerationFunctionEditionEnabled',
          checkVisibleField: 'signerCreationFormDefineFunction',
          required: false,
          rules: 'required',
        },
      ],
    };
  },
};
</script>
