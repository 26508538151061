export default {
  methods: {
    USBWebSocket_sendMessage(socketClient, sourceId, message, messageUtid) {
      if (message.length > 3500) {
        this.USBWebSocket_sendMessageByParts(socketClient, sourceId, message, messageUtid);
        return;
      }

      const lMessageToken = JSON.stringify({
        ns: 'org.jWebSocket.plugins.system',
        type: 'send',
        msg: message,
        targetId: sourceId,
        utid: this.clientParams.utid,
      });
      socketClient.send(lMessageToken);
    },

    USBWebSocket_sendMessageByParts(socketClient, sourceId, businessMessage, messageUtid) {
      let offset = 0;
      const limit = 3500;

      const messageId = this.$uuid.v4();

      while (offset < businessMessage.length) {
        const token = JSON.stringify({
          ns: 'org.jwebsocket.plugins.system',
          type: 'send',
          name: 'messagePart',
          data: businessMessage.slice(offset, offset + limit),
          messageId,
          targetId: sourceId,
          utid: messageUtid,
        });

        socketClient.send(token);
        offset += limit;
      }

      const token = JSON.stringify({
        ns: 'org.jwebsocket.plugins.system',
        type: 'send',
        name: 'messageFullyTransmitted',
        data: businessMessage.slice(offset, offset + limit),
        messageId,
        targetId: sourceId,
        utid: messageUtid,
      });

      socketClient.send(token);
    },
  },
};
