<template>
  <form @submit.prevent="sign" id="signatureUsbForm">
    <div class="flex flex-col items-center">
      <svg xmlns="http://www.w3.org/2000/svg" width="255" height="213" viewBox="0 0 255 213">
        <g fill="none" fill-rule="evenodd">
          <circle cx="165.5" cy="89.5" r="89.5" fill="#E2E5EC"/>
          <g>
            <path fill="#E7E4DD" stroke="#B1AEA6" d="M76.674 65.343H13.298c-1.855 0-3.373-1.517-3.373-3.369V3.37C9.925 1.517 11.443 0 13.298 0h63.376c1.857 0 3.373 1.517 3.373 3.369v58.605c0 1.852-1.516 3.37-3.373 3.37" transform="rotate(56 66.891 174.499)"/>
            <path fill="#BEC2C3" d="M23.789 27.531L35.162 27.531 35.162 16.394 23.789 16.394z" transform="rotate(56 66.891 174.499)"/>
            <path fill="#B1AEA6" d="M23.789 18.053L35.162 18.053 35.162 16.394 23.789 16.394z" transform="rotate(56 66.891 174.499)"/>
            <path fill="#B1AEA6" d="M33.5 27.531L35.162 27.531 35.162 16.394 33.5 16.394z" transform="rotate(56 66.891 174.499)"/>
            <path fill="#BEC2C3" d="M54.808 27.531L66.182 27.531 66.182 16.394 54.808 16.394z" transform="rotate(56 66.891 174.499)"/>
            <path fill="#B1AEA6" d="M54.808 18.053L66.182 18.053 66.182 16.394 54.808 16.394z" transform="rotate(56 66.891 174.499)"/>
            <path fill="#01A0D6" d="M0 49.238V163.42c0 24.803 20.14 44.91 44.986 44.91 24.845 0 44.986-20.107 44.986-44.91V49.24H0" transform="rotate(56 66.891 174.499)"/>
            <path fill="#FFFFFE" d="M17.181 170.5L72.791 170.5 72.791 72.491 17.181 72.491z" transform="rotate(56 66.891 174.499)"/>
            <path fill="#01A0D6" d="M27.97 158.527c-1.948 0-3.527-1.576-3.527-3.521v-51.06c0-1.945 1.579-3.523 3.527-3.523 1.95 0 3.529 1.578 3.529 3.523v51.06c0 1.945-1.58 3.521-3.53 3.521M27.97 97.354c-1.948 0-3.527-1.576-3.527-3.523v-12.4c0-1.946 1.579-3.522 3.527-3.522 1.95 0 3.529 1.576 3.529 3.521v12.401c0 1.947-1.58 3.523-3.53 3.523" transform="rotate(56 66.891 174.499)"/>
            <path fill="#B1AEA6" d="M64.826 27.531L66.488 27.531 66.488 16.394 64.826 16.394z" transform="rotate(56 66.891 174.499)"/>
          </g>
        </g>
      </svg>
      <span class="font-bold">{{ signDialogData.usbSignatureInfo }}</span>
    </div>
  </form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import axios from 'axios';
import USBWebSocket from '../../../../../mixins/USBWebSocket';
import SignworkflowsApi from '../../../../../api/signworkflows';

const WebSocketClient = require('websocket').w3cwebsocket;

export default {
  name: 'SignatureUsb',
  mixins: [USBWebSocket],
  inject: ['signDialogData'],
  props: {
    signworkflows: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      clientParams: {
        utid: 2,
        socketCallId: 1,
        maxFrameSize: 10000,
        docsSent: false,
        signedDocs: [],
      },
      // webSocket client for usb signature
      client: undefined,
      pkcs11JarName: '',
    };
  },
  watch: {
    'signDialogData.hasCanceled': function(value) {
      if (value) {
        this.signDialogData.usbSignatureInfo = '';
        this.signDialogData.hasCanceled = false;
      }
    },
  },
  computed: {
    ...mapGetters({
      user: 'ModuleEdocSign/auth/user',
    }),
  },
  methods: {
    ...mapActions({
      sendSignature: 'ModuleEdocSign/parapheurs/sendSignature',
      sendUSBSignature: 'ModuleEdocSign/parapheurs/sendUSBSignature',
    }),
    // web socket and applet for usb key signature
    onMounted(signBooks) {
      this.user.logo = this.signDialogData.certificate.logo;
      const promisesWSSignbook = signBooks.flatMap((x) => this.changeSignBooksForWebSocket(x, this.user));
      Promise.all(promisesWSSignbook).then((response) => {
        const docsToSend = response;
        this.displayUsbSignatureInfo('Connection au service en cours...');
        this.client = new WebSocketClient(`wss://${this.webSocketEndPoint()}/wssBoPKCS11Communication/jWebSocket/jWebSocket`, null);
        this.displayUsbSignatureInfo('Veuillez insérez votre clé USB');
        this.client.onerror = () => {
          this.client.close();
          if (!this.signDialogData.hasCanceled) {
            this.onMounted(signBooks);
          }
        };
        this.client.onopen = () => {
          console.log('Websocket connected !');
        };
        this.client.onclose = () => {
          this.displayUsbSignatureInfo('Connection ');
          console.log('Connection closed !');
        };
        this.client.onmessage = (message) => {
          this.handleWebSocketEvent(JSON.parse(message.data), docsToSend);
        };
      });
    },
    sign() {
      const promises = [];
      for (let i = 0; i < this.signworkflows.length; i++) {
        if (!(this.signworkflows[i].nbStepsRealized >= this.signworkflows[i].nbSteps)) {
          promises.push(this.sendSignature({ signBookId: this.signworkflows[i].signBookId, signatureCode: this.signDialogData.signatureCode }));
        }
      }
      axios.all(promises)
        .then(() => {
          this.signDialogData.signatureStatus = 'success';
          if (this.$route.name === 'guestSignworkflows' || !this.$store.state.ModuleEdocSign.auth.session.guest) {
            this.getSignworkflows(this.searchParams)
              .then(() => {
                this.getUsageDatas()
                  .then(() => {
                    this.finishLoading();
                  });
              });
          } else {
            this.finishLoading();
          }
        }).catch(() => {
          this.signDialogData.signatureStatus = 'error';
          this.finishLoading();
        }).finally(() => {
          this.signDialogData.goToStep(3);
        });
    },
    signDoc(sourceId) {
      this.displayUsbSignatureInfo('Signature des documents en cours...');
      const messageContent = {
        namespace: 'edoc.pkcs11signer',
        type: 'signDocuments',
        ref: {
          function: 'signDocuments', function_id: this.clientParams.socketCallId,
        },
        content: {
          jsFunction: 'SignbookSigningForm_',
        },
      };

      this.clientParams.utid += 1;
      this.sendDirectMessage(sourceId, JSON.stringify(messageContent));
      this.clientParams.socketCallId += 1;
    },
    updateDocAfterSign(args) {
      this.displayUsbSignatureInfo('Mise à jour des documents signés en cours...');
      // reconsitution des documents signé
      if (this.clientParams.signedDocs.find((x) => x.id === args[0])) {
        this.clientParams.signedDocs.find((x) => x.id === args[0]).fileContent += window.atob(args[1]);
      } else {
        this.clientParams.signedDocs.push({ id: args[0], fileContent: window.atob(args[1]) });
      }
    },
    handleEnd(args, docsToSend) {
      if (args[0]) {
        this.displayUsbSignatureInfo('Signature effectuée avec succès !');

        console.log(this.clientParams.signedDocs);
        this.clientParams.signedDocs = this.clientParams.signedDocs.map((x) =>
          ({ id: x.id, fileContent: window.btoa(x.fileContent) }));
        console.log(this.clientParams.signedDocs);

        const promises = [];
        for (let i = 0; i < this.signworkflows.length; i += 1) {
          promises.push(this.sendUSBSignature({
            signBookId: this.signworkflows[i].signBookId,
            documents: this.clientParams.signedDocs
              .filter((x) => docsToSend
                .find((y) => x.id === y.document.signBookDocumentId).signBookId === this.signworkflows[i].signBookId),
          }));
        }

        Promise.all(promises)
          .then(() => {
            this.getSignworkflows(this.searchParams)
              .then(() => {
                this.getUsageDatas()
                  .then(() => {
                    this.$emit('closeDialog', true);
                  });
              });
          });
      } else {
        this.displayUsbSignatureInfo('Signature avortée.');
        this.signDialogData.signatureStatus = 'error';
        this.client.close();
      }
      this.signDialogData.signatureStatus = 'success';
      this.signDialogData.goToStep(4.2);
    },

    webSocketEndPoint() {
      if (process.env.NODE_ENV === 'development') {
        return process.env.VUE_APP_WEBSOCKET_URL;
      }
      return document.location.hostname;
    },
    // handle web socket event, console infos and redirect to approriate answer function
    handleWebSocketEvent(message, docsToSend) {
      console.log('Server message handled, type is:', message.type);
      switch (message.type) {
        case 'welcome':
          this.displayUsbSignatureInfo('Initialisation réalisée, en attente de communication avec la clé...');
          console.log(`SourceId of connection: ${message.sourceId}`);

          this.downloadJNLP(message.sourceId);
          this.sendLogin(message.sourceId);
          break;
        case 'login':
          this.displayUsbSignatureInfo('Identification réalisée');
          break;
        case 'info':
          console.log(`${message.name}: ${message.data}`);
          if (message.name === 'maxFrameSize') {
            this.clientParams.maxFrameSize = message.data;
            console.log('New maxFrameSize saved ! ');
          }
          break;
        case 'event':
          this.displayUsbSignatureInfo(`${message.name}`);
          if (message.name === 'disconnect') {
            console.log('Received disconnect event');
            this.client.close();
            this.client = undefined;
            this.clientParams = {
              utid: 2,
              socketCallId: 1,
              maxFrameSize: 2000000,
              docsSent: false,
              signedDocs: [],
            };
          }
          break;
        case 'send':
          this.clientParams.utid = message.utid;
          const data = JSON.parse(message.data);

          // handle informations from websocket to display to user or callback function to update data
          if (data.complementaryInfos) {
            if (data.waitingMessage) {
              this.displayUsbSignatureInfo(data.waitingMessage);
            } else if (data.functionToLaunch) {
              this.displayUsbSignatureInfo(`Launch function: ${data.functionToLaunch}`);
              if (data.functionToLaunch === 'SignbookSigningForm_filePartLoaded') {
                this.updateDocAfterSign(data.args);
              } else if (data.functionToLaunch === 'SignbookSigningForm_pkcs11SignatureEnded') {
                this.handleEnd(data.args, docsToSend);
              }
            } else {
              this.displayUsbSignatureInfo(`${message}`);
            }
          }

          // on the first send message received, send all document and required information
          if (!this.clientParams.docsSent) {
            this.clientParams.docsSent = true;
            this.sendDirectMessage(message.sourceId, 'connection successfully done');
            this.clientParams.utid += 1;
            this.sendReset(message.sourceId);
            this.sendDoc(message.sourceId, docsToSend);
          }
          break;
        case 'response':
          console.log(`Answer to request of type ${message.reqType}: ${message.msg}`);
          break;
        default:
          console.log(`Complementary message: ${message}`);
          break;
      }
    },
    sendDocContent(sourceId, doc, docPart, offset) {
      this.clientParams.utid += 1;
      const content = JSON.stringify({
        data: docPart,
        ref: {
          function: 'updateDocumentContent',
        },
        details: JSON.stringify(doc),
        offset,
        limit: 3500,
        partSize: docPart.length,
      });
      // content = window.btoa(content);

      const lMessageToken = JSON.stringify({
        ns: 'org.jwebsocket.plugins.filesystem',
        type: 'send',
        data: content,
        targetId: sourceId,
        utid: this.clientParams.utid,
      });
      this.client.send(lMessageToken);
    },
    displayUsbSignatureInfo(message) {
      console.log(message);
      this.signDialogData.usbSignatureInfo = message;
    },
    sendDoc(sourceId, docsToSend) {
      this.displayUsbSignatureInfo('Envoi des documents à signer en cours...');
      let messageContent;
      for (let i = 0; i < docsToSend.length; i++) {
        const docForApplet = {};
        Object.assign(docForApplet, docsToSend[i]);
        delete docForApplet.document.fileContent;

        messageContent = {
          namespace: 'edoc.pkcs11signer',
          type: 'addDocument',
          ref: { 'function': 'addDocument', 'function_id': this.clientParams.socketCallId },
          content: {
            document: JSON.stringify(docForApplet.document),
            signatureParams: JSON.stringify(docForApplet.signatureParams),
          },
        };

        console.log(docsToSend[i]);

        this.clientParams.utid += 1;
        this.USBWebSocket_sendMessage(this.client, sourceId, JSON.stringify(messageContent), this.clientParams.utid);
        // this.sendDirectMessage(sourceId, JSON.stringify(messageContent));

        this.clientParams.socketCallId += 1;

        const docDetails = {};
        docDetails.fileSize = docForApplet.document.fileSize;
        docDetails.parapherReference = docForApplet.document.parapherReference;

        if (docsToSend[i].document.fileSize > 3500) {
          let offset = 0;
          const limit = 3500;
          const { fileContent } = docsToSend[i];
          const { fileSize } = docsToSend[i].document;
          let filePart;
          while (offset < fileSize) {
            filePart = fileContent.slice(offset, offset + limit);
            this.sendDocContent(sourceId, docDetails, filePart, offset);
            offset += limit;
          }
        } else {
          this.sendDocContent(sourceId, docDetails, docsToSend[i].fileContent, 0);
        }
      }

      console.log('start signing doc');
      this.signDoc(sourceId);
    },
    sendReset(sourceId) {
      this.sendDirectMessage(sourceId, JSON.stringify({
        namespace: 'edoc.pkcs11signer',
        type: 'reset',
        ref: { function: 'reset', function_id: 0 },
        content: {},
      }));
    },
    sendDirectMessage(aSourceId, aMessage) {
      if (aSourceId) {
        if (aMessage !== null || aMessage !== '') {
          const lMessageToken = JSON.stringify({
            ns: 'org.jWebSocket.plugins.system',
            type: 'send',
            msg: aMessage,
            targetId: aSourceId,
            utid: this.clientParams.utid,
          });
          this.client.send(lMessageToken);
        }
      } else {
        this.displayUsbSignatureInfo('Error while sending direct message');
      }
    },
    sendLogin(sourceId) {
      const lMessageToken = JSON.stringify({
        ns: 'org.jWebSocket.plugins.system',
        type: 'login',
        username: 'guest',
        password: 'guest',
        utid: this.clientParams.utid,
        sourceId,
      });
      this.client.send(lMessageToken);
    },
    downloadJNLP(sourceId) {
      const dwld = document.createElement('a');
      let content = `<?xml version="1.0" encoding="utf-8"?>


    <jnlp spec="1.0+" codebase="https://edocparaph.fr">
      <information>
        <title>Edoc</title>
        <vendor></vendor>
        <homepage href="https://edocsign.fr" />
        <description></description>
      </information>
      <security>
        <all-permissions/>
      </security>
      <resources>
        <j2se version="1.7+"  />
        <jar href="./datas/jws/pkcs11Signer/updatedAppletPkcs11Signer-demoedocsign.jar" version="1.0" />
        <!--
        <nativelib href="./datas/jws/pkcs11Signer/appletPkcs11Dlls.jar" version="1.0"></nativelib>
        -->
        <property name="jnlp.versionEnabled" value="true"/>
        <property name="jnlp.server_host" value="wss://edocparaph.fr"/>
        <property name="jnlp.server_proxy" value="/wssBoPKCS11Communication/jWebSocket/jWebSocket"/>
      </resources>
      <resources arch="x86">
        <nativelib href="./datas/jws/pkcs11Signer/appletPkcs11Dlls.jar" version="1.0"></nativelib>
      </resources>
      <resources arch="i386">
                    <nativelib href="./datas/jws/pkcs11Signer/appletPkcs11Dlls.jar" version="1.0"></nativelib>
            </resources>
      <resources arch="amd64">
                    <nativelib href="./datas/jws/pkcs11Signer/appletPkcs11Dlls-64.jar" version="1.0"></nativelib>
            </resources>
      <resources arch="x86_64">
                    <nativelib href="./datas/jws/pkcs11Signer/appletPkcs11Dlls-64.jar" version="1.0"></nativelib>
            </resources>
      <application-desc main-class="com.edoc.updatedappletpkcs11signer.Main" >
        <argument>${sourceId}</argument>
      </application-desc>
    </jnlp>`;
      const url = `data:application/x-java-jnlp-file:charset=UTF-8,${encodeURIComponent(content)}`;
      dwld.setAttribute('href', url);
      dwld.setAttribute('download', 'pkcs11Signer.jnlp');
      dwld.style.display = 'none';
      document.body.append(dwld);
      dwld.click();
      document.body.removeChild(dwld);
    },
    changeSignBooksForWebSocket(signBook, user) {
      const promises = [];

      for (let i = 0; i < signBook.documents.length; i++) {
        const steps = signBook.steps.filter((x) => x.docsToSign.includes(signBook.documents[i].signBookDocumentId));
        for (let j = 0; j < steps.length; j++) {
          let signatureParamsTmp = steps[j].captiveZones.filter((x) => x.positions.filter((y) => signBook.signBookId + '_' + y.docId === signBook.documents[i].parapherReference));
          let signatureParams = { positions: [] };
          signatureParamsTmp.forEach((x) => {
            x.positions.forEach((y) => {
              signatureParams = {
                applyToEachPages: y.applyToEachPages,
                docId: y.docId,
                height: y.height,
                mandatSignatureFieldsToShow: y.mandatSignatureFieldsToShow,
                page: y.page,
                token: y.token,
                type: y.positionType,
                width: y.width,
                x: y.x,
                y: y.y,
              };
            });
          });
          signatureParams.signWithMandat = true;
          if (user.logo && signatureParams) {
            signatureParams.logoContent = user.logo;
          } else {
            signatureParams = { logoContent: user.logo };
          }
          const docShouldBeSigned = steps[j].docsToSign.includes(signBook.documents[i].signBookDocumentId);
          if (docShouldBeSigned) {
            const promise = SignworkflowsApi.getDocContent({ id: signBook.documents[i].signBookDocumentId }).then((response) => {
              const { status, data } = response;
              if (status === 'success') {
                signBook.documents[i].fileSize = data.fileContent.length;
                return {
                  document: signBook.documents[i],
                  signatureParams,
                  fileContent: data.fileContent,
                  signBookId: signBook.signBookId,
                };
              }
            });
            promises.push(promise);
          }
        }
      }

      return promises;
    },
  },
  mounted() {
    this.signDialogData.hasCanceled = false;
    this.onMounted(this.signworkflows);
    this.pkcs11JarName = 'updatedAppletPkcs11Signer-demoedocsign.jar';
  },
};
</script>
